.avatar-inner {
  background: #343140;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border: 2px solid white;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 1px;
  padding: 6px;
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 13px;
}

.avatar-inner-hover:hover {
  z-index: 2;
  border-color: black;
}

.avatar-inner:not(:last-child) {
  margin-left: -0.75rem;
}

.avatar-holder {
  margin-left: 0.75rem;
}

.big-icon {
  font-size: 1.5rem;
}
